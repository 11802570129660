import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const ALBERTO_BINI_SALES = {
    storage_key: 'alb_sales',
    active: false, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'Alberto Bini Outlet',
    store_code: 'ALBERTO_BINI_SALES',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Cocktail Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: true,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6, height: 9 },

        main_price_multiplication: 1,
        sale_price_multiplication: 1.5,
        currency_multiplication: 40,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: '₴',
        custom_logo: false,
        orderPriority: 5,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/alb_sales.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Аутлет Alberto Bini пропонує чудову можливість придбати стильний жіночий одяг за вигідними цінами. У нас ви знайдете минулі колекції та унікальні вироби, виконані з високоякісних натуральних матеріалів. Ми пропонуємо різноманіття моделей, які поєднують комфорт та елегантність, щоб ви могли виглядати бездоганно в будь-якій ситуації.',
            },
            {
                language: 'en',
                description:
                    "Alberto Bini Outlet offers a great opportunity to purchase stylish women's clothing at attractive prices. Here, you will find past collections and unique pieces made from high-quality natural materials. We offer a variety of styles that combine comfort and elegance, ensuring you look flawless in any situation.",
            },
        ],
        types: [3, 5, 6, 12, 13, 14, 17, 31],
        gender: [GenderType?.FEMALE],
        rank: 1,
    },
};
